<template>
  <div id="topiclocal2">
    <div class="title">
      <b>*手动添加{{ fileName }}</b>
    </div>
    <main>
      <div class="topictype">
        <div class="left">
          <p>{{ fileName }}类型</p>
          <el-select v-model="type" :placeholder="'请选择' + fileName + '类型'">
            <el-option label="选择题" value="选择题"></el-option>
            <el-option
              v-if="this.$route.query.filetype == 8"
              label="判断题"
              value="判断题"
            ></el-option>
          </el-select>
        </div>
        <div v-if="this.$route.query.filetype == 8" class="right">
          <p>正确答案</p>
          <el-select v-model="option" placeholder="请选择正确答案">
            <el-option label="选项A" value="A"></el-option>
            <el-option label="选项B" value="B"></el-option>
            <el-option label="选项C" value="C"></el-option>
            <el-option label="选项D" value="D"></el-option>
          </el-select>
        </div>
      </div>
      <div class="timu">
        <p>{{ fileName }}题干</p>
        <div class="localFirst"></div>
      </div>

      <div class="choose chose">
        <p>选项A</p>
        <div class="choseA"></div>
      </div>

      <div class="choose chose">
        <p>选项B</p>
        <div class="choseB"></div>
      </div>

      <div class="choose chose">
        <p>选项C</p>
        <div class="choseC"></div>
      </div>

      <div class="choose chose">
        <p>选项D</p>
        <div class="choseD"></div>
      </div>

      <div v-show="this.$route.query.filetype == 8" class="choose chose">
        <p>{{ fileName }}解析</p>
        <div class="desecript"></div>
      </div>
    </main>
    <div
      class="addtopic"
      v-show="this.$route.query.index == ''"
      @click="addtopic"
    >
      添加{{ fileName }}
    </div>
    <div
      class="addtopic"
      v-show="this.$route.query.index != ''"
      @click="addtopic"
    >
      修改{{ fileName }}
    </div>
  </div>
</template>
<script>
import * as util from "@/unit/network";
// import { util } from "echarts";
// import $ from "jquery";
import wangEditor from "wangeditor";
export default {
  data() {
    return {
      type: "", // 试题类型
      option: "", // 试题选项
      json: [],
      fileName: "",
    };
  },
  mounted() {
    if (this.$route.query.filetype == "6") {
      this.fileName = "问卷";
    } else {
      this.fileName = "试题";
    }
    const editor = new wangEditor(`.localFirst`); //题干

    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.editorData = newHtml;
    };

    // 创建编辑器
    editor.config.height = 128;
    editor.config.excludeMenus = [
      "emoticon",
      "video",
      "justify",
      "code",
      "quote",
      "emoticon",
      "backColor",
      "link",
      "list",
      "todo",
      "justify",
      "indent",
      "lineHeight",
    ];
    // editor.config.withCredentials = true;

    this.uploadImg(editor);
    editor.create();

    this.editor = editor;

    const chose1 = new wangEditor(".choseA"); //选项A
    chose1.config.onchange = (newHtml) => {
      this.editorData = newHtml;
    };

    // 创建编辑器
    chose1.config.height = 101;
    chose1.config.excludeMenus = [
      "emoticon",
      "video",
      "justify",
      "code",
      "quote",
      "emoticon",
      "backColor",
      "link",
      "list",
      "todo",
      "justify",
      "indent",
      "lineHeight",
    ];
    this.uploadImg(chose1);
    chose1.create();

    this.chose1 = chose1;

    const chose2 = new wangEditor(".choseB"); //选项B
    chose1.config.onchange = (newHtml) => {
      this.editorData = newHtml;
    };

    // 创建编辑器
    chose2.config.height = 101;
    chose2.config.excludeMenus = [
      "emoticon",
      "video",
      "justify",
      "code",
      "quote",
      "emoticon",
      "backColor",
      "link",
      "list",
      "todo",
      "justify",
      "indent",
      "lineHeight",
    ];
    this.uploadImg(chose2);
    chose2.create();

    this.chose2 = chose2;

    const chose3 = new wangEditor(".choseC"); //选项C
    chose3.config.onchange = (newHtml) => {
      this.editorData = newHtml;
    };

    // 创建编辑器
    chose3.config.height = 101;
    chose3.config.excludeMenus = [
      "emoticon",
      "video",
      "justify",
      "code",
      "quote",
      "emoticon",
      "backColor",
      "link",
      "list",
      "todo",
      "justify",
      "indent",
      "lineHeight",
    ];
    this.uploadImg(chose3);
    chose3.create();

    this.chose3 = chose3;

    const chose4 = new wangEditor(".choseD"); //选项D
    chose4.config.onchange = (newHtml) => {
      this.editorData = newHtml;
    };

    // 创建编辑器
    chose4.config.height = 101;
    chose4.config.excludeMenus = [
      "emoticon",
      "video",
      "justify",
      "code",
      "quote",
      "emoticon",
      "backColor",
      "link",
      "list",
      "todo",
      "justify",
      "indent",
      "lineHeight",
    ];
    this.uploadImg(chose4);
    chose4.create();

    this.chose4 = chose4;

    const desecript = new wangEditor(".desecript"); //解析
    desecript.config.onchange = (newHtml) => {
      this.editorData = newHtml;
    };

    // 创建编辑器
    desecript.config.height = 101;
    desecript.config.excludeMenus = [
      "emoticon",
      "video",
      "justify",
      "code",
      "quote",
      "emoticon",
      "backColor",
      "link",
      "list",
      "todo",
      "justify",
      "indent",
      "lineHeight",
    ];
    this.uploadImg(desecript);
    desecript.create();

    this.desecript = desecript;

    let jsonstr = window.localStorage.getItem(
      "jsonkey" + this.$route.query.dataid
    );
    let json = JSON.parse(jsonstr);
    this.json = json;
    if (this.$route.query.index) {
      this.getinfo();
    }
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
  methods: {
    getinfo() {
      let list = JSON.parse(
        localStorage.getItem("jsonkey" + this.$route.query.dataid)
      );
      let i = this.$route.query.index;
      this.type = list[i].type;
      this.option = list[i].option;
      this.editor.txt.html(list[i].timu);
      this.chose1.txt.html(String(list[i].choseA));
      this.chose2.txt.html(String(list[i].choseB));
      this.chose3.txt.html(String(list[i].choseC));
      this.chose4.txt.html(String(list[i].choseD));
      this.desecript.txt.html(String(list[i].desecript));
    },
    addtopic() {
      if (this.$route.query.index == "") {
        if (this.json == null) {
          this.json = [
            {
              type: this.type,
              timu: this.editor.txt.html(),
              choseA: this.chose1.txt.html(),
              choseB: this.chose2.txt.html(),
              choseC: this.chose3.txt.html(),
              choseD: this.chose4.txt.html(),
              desecript: this.desecript.txt.html(),
              option: this.option,
            },
          ];
        } else {
          this.json.push({
            type: this.type,
            timu: this.editor.txt.html(),
            choseA: this.chose1.txt.html(),
            choseB: this.chose2.txt.html(),
            choseC: this.chose3.txt.html(),
            choseD: this.chose4.txt.html(),
            desecript: this.desecript.txt.html(),
            option: this.option,
          });
        }

        // console.log(this.json);
        let jsonstr = JSON.stringify(this.json);
        window.localStorage.setItem(
          "jsonkey" + this.$route.query.dataid,
          jsonstr
        );
        this.$router.push({
          path: "/main/addscene/topicresource/topiclocal",
          query: {
            csid: this.$route.query.csid,
            dataid: this.$route.query.dataid,
            filetype: this.$route.query.filetype,
          },
        });
      } else {
        console.log();
        let list = JSON.parse(
          localStorage.getItem("jsonkey" + this.$route.query.dataid)
        );
        let i = this.$route.query.index;
        let str = {
          type: this.type,
          timu: this.editor.txt.html(),
          choseA: this.chose1.txt.html(),
          choseB: this.chose2.txt.html(),
          choseC: this.chose3.txt.html(),
          choseD: this.chose4.txt.html(),
          desecript: this.desecript.txt.html(),
          option: this.option,
        };
        list[i].type = str.type;
        list[i].option = str.option;
        list[i].timu = str.timu;
        list[i].choseA = str.choseA;
        list[i].choseB = str.choseB;
        list[i].choseC = str.choseC;
        list[i].choseD = str.choseD;
        list[i].desecript = str.desecript;
        localStorage.setItem(
          "jsonkey" + this.$route.query.dataid,
          JSON.stringify(list)
        );
        this.$router.push({
          path: "/main/addscene/topicresource/topiclocal",
          query: {
            csid: this.$route.query.csid,
            dataid: this.$route.query.dataid,
            filetype: this.$route.query.filetype,
          },
        });
      }
    },
    // upload(i) {
    //   console.log(i);
    //   util.post_file("/upload_img", i);
    // },
    uploadImg(i) {
      console.log(i);
      // i.config.uploadImgServer =
      //   "http://zy.yunlearning.com:8083/api/upload_img"yyyyyyyyyyyyyyyyyyyyyyyyiiiiiiiiiiiiiiiiiiiii0'''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''';
      // i.config.uploadFileName = "file";
      // i.config.uploadImgHeaders = {
      //   "Access-Control-Allow-Origin": "*",
      // };
      // i.config.uploadImgParams = {
      //   token: this.getCookie("token"),
      // };
      // i.config.uploadImgHooks = {
      //   customInsert: function (insertImg, result, editor) {
      //     // 图片上传并返回结果，自定义插入图片的事件（而不是编辑器自动插入图片！！！）
      //     // insertImg 是插入图片的函数，editor 是编辑器对象，result 是服务器端返回的结果
      //     console.log(editor);
      //     // 举例：假如上传图片成功后，服务器端返回的是 {url:'....'} 这种格式，即可这样插入图片：
      //     var url = result.data;
      //     insertImg(url);

      //     // result 必须是一个 JSON 格式字符串！！！否则报错
      //   },
      // };
      // var that = this;
      // this.editor.customConfig.customUploadImg = async (files, insert) => {
      i.config.customUploadImg = (files, insert) => {
        /* files 是 input 中选中的文件列表 */
        let formData = new FormData();
        formData.append("file", files[0]);
        // let data = that.upload(formData);
        util.post_file("/upload_img", formData, (res) => {
          // console.log(res);
          console.log(res.data);
          insert(res.data);
        });
        // let data = await util.post_file("/upload_img", formData, (res) => {
        //   console.log(res);
        // });
        /* upload方法是后台提供的上传图片的接口 */
        /* insert 是编辑器自带的 获取图片 url 后，插入到编辑器的方法 上传代码返回结果之后，将图片插入到编辑器中*/
        // console.log(data);
        // console.log(insert);
      };
    },
    getCookie(name) {
      var arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
      else return null;
    },
  },
  components: {},
};
</script>
<style lang="less" scoped>
#topiclocal2 {
  height: 100%;
  // background-color: #fff;
  main {
    margin-top: 24px;
    margin-left: 15px;
    .topictype {
      display: flex;
      > div {
        display: flex;
        align-items: center;
        margin-right: 121px;
        font-size: 16px;
        /deep/.el-input__inner {
          width: 160/96in;
        }
        p {
          margin-right: 9px;
        }
      }
    }
    .timu {
      display: flex;
      margin-top: 43px;
      margin-bottom: 38px;
      p {
        margin-right: 9px;
      }
      .localFirst {
        width: 1125px;
        min-height: 158px;
        z-index: 2;
      }
    }
    // 选项
    .choose {
      display: flex;
      margin-bottom: 33px;
      > div {
        width: 600px;
      }
      p {
        width: 64/96in;
        text-align: right;
        margin-right: 9px;
      }
      .chose {
        width: 692px;
        height: 131px;
      }
    }
  }
  .addtopic {
    width: 100/96in;
    height: 36/96in;
    background: #1c5eff;
    border-radius: 5px;
    color: #fff;
    line-height: 36/96in;
    text-align: center;
    margin-left: 591px;
    margin-top: 80px;
    cursor: pointer;
  }
}
</style>